export default {
  cake: {
    56: '0x5c46c55a699a6359e451b2c99344138420c87261',
    97: '',
  },
  masterChef: {
    56: '0x1432D023E5655fBee9dC2831e92E26A4E0aC53eF',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },

  usdt :{
    56: '0x55d398326f99059ff775485246999027b3197955',
    97: '',
  },

  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
