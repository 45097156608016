import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [



  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'iBG',
    lpAddresses: {
      97: '',
      56: '0x36B8eE5c1f8FFB3b224736Ca9c989B1c531a613d', // EGG-BUSD LP
    },
    tokenSymbol: 'iBG',
    tokenAddresses: {
      97: '',
      56: '0x5c46c55A699A6359E451B2c99344138420c87261',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  }
]

export default farms
